th {
    color: #9d47df !important;
    text-transform: initial !important;
}

td {
    color: #555 !important;
}

h2 {
    color: #221f28 !important;
}

label {
    color: #8C8C8C !important;
}

[class*="CatalogReactUserListPicker-root-"] {
    background-color: transparent !important;
}

[class*="MuiListItem-root-"] {
    background-color: transparent !important;
}

[class*="MuiSelect-root-"] {
    border: 0 !important;
    border-bottom: 1px solid #D9D9D9 !important;
}

fieldset {
    border: 0 !important;
    border-bottom: 1px solid #D9D9D9 !important;
}

.Ticket {
    font-weight: bolder;
    text-align: center;
    background: #6e00d412;
    box-shadow: 2px 8px 9px -9px;
}